<template>
  <div>
    <div class="table_content">
      <!-- 表格内容 -->
      <commonTitle></commonTitle>

      <div class="search">
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="楼幢id:">
            <el-input v-model="form.towerNum" size="mini" placeholder="请输入小区名称"></el-input>
          </el-form-item>

          <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
          <button @click.prevent="exportExcel()" style="cursor: pointer;">导出</button>
        </el-form>
      </div>
      <!--表格-->
      <template>
        <el-card>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            v-loading="loading"
            element-loading-text="拼命加载中"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="楼幢id" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="lookUnit(scope.$index, scope.row)"
                >{{scope.row.towerNum}}</el-link>
              </template>
            </el-table-column>
            <el-table-column property="assessmentSum" label="应缴金额" show-overflow-tooltip></el-table-column>
            <el-table-column property="amountPaySum" label="已缴金额" show-overflow-tooltip></el-table-column>
            <el-table-column property="standardAmountSum" label="标准金额" show-overflow-tooltip></el-table-column>
            <el-table-column property="houseacctBalSum" label="账户余额" show-overflow-tooltip></el-table-column>
          </el-table>
          <el-pagination
            background
            style="margin-top: 50px"
            layout="prev, pager, next"
            :page-size="15"
            @current-change="handleCurrentChange"
            :total="totalNum"
          ></el-pagination>
          <el-button @click="back" class="btn" size="small">上一步</el-button>
        </el-card>
      </template>
    </div>
  </div>
</template>

<script>
import commonTitle from "../table/commonTitle";
var villageId = JSON.parse(localStorage.getItem("villageData")).villageId;
export default {
  name: "reportTower",
  components: {
    commonTitle
  },
  data() {
    return {
      form: {
        towerNum: ""
      },
      tableData: [],
      multipleSelection: [],
      totalNum: 15,
      url: this.$Config.base_server,
      loading: false,
      isShow: "",
      data: {
        access_token: localStorage.getItem("token"),
        limit: 15,
        villageId: JSON.parse(localStorage.getItem("villageData")).villageId
      }
    };
  },
  components: {
    commonTitle
  },
  mounted() {
    this.common();
  },
  methods: {
    common(currentPage = 0) {
      const that = this;
      that.loading = true;

      that.data.page = currentPage;
      //搜索框的值
      that.data.towerNum = that.form.towerNum;
      $.ajax({
        url: this.url + "/api-public/report/towerRepairReport",
        data: that.data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          that.loading = false;
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
        },
        error: function(res) {
          that.loading = false;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    lookUnit(index, row) {
      localStorage.setItem("towerData", JSON.stringify(row));
      this.$router.push({ name: "reportUnit" });
    },
    back() {
      //返回按钮
      this.$router.push("/villagePlan");
    },
    exportExcel() {
      var towerNumVal = this.form.towerNum;
      var url =
        this.url +
        "/api-public/report/towerRepairReportExport?access_token=" +
        localStorage.getItem("token") +
        "&towerNum=" +
        towerNumVal +
        "&villageId=" +
        villageId;
      location.href = url;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/table/first_table.css";

.el-button {
  padding: 5px 5px;
}
.btn {
  float: right;
  transform: translateY(-34px);
}
</style>
